<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import { FormWizard, TabContent } from "vue-form-wizard";
import axios from 'axios';
import Multiselect from "vue-multiselect";
import { setValue, CurrencyDirective, getValue } from "vue-currency-input";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: { Layout, PageHeader, FormWizard, TabContent , Multiselect,Loading},
  data() {
    return {
      title: "Send Money",
      desCon:'',
      destination_country:[],
      sendAmount: '',
      receivedAmount: '',
      valueDesCon: '',
      users:[],
      userID:'',
      recs:[],
      recID:'',
      purpose: [],
      purposeID : '',
      source : [],
      sourceID : '',
      invoice: null,
      balance :[],
      inv_num: '',
      note: '',
      isLoaded: false,
      isLoaded2: false,
      isLoading: false,
      fullPage: true,
      isDisable: false,
      isDisabled: true,
      isdisabd: false,
      recipients: {
            f_name : '',
            l_name : '',
            company_name : '',
            user_id : '',
            des_country : '',
            bank_name : '',
            usd_bank_name : '',
            acc_number : '',
            phone_n : '',
            iban : '',
            swift : '',
            bsb_code : '',
            ifsc : '',
            bik_code : '',
            transit_code : '',
            sort_code : '',
            branch_name : '',
            branch_code : '',
            address : '',
            bank_add : '',
            city : '',
            province_r : '',
            postalcode : '',
            region : '',
            email : '',
            account_type : '',
            id_type : '',
            id_number : '',
            birth_date : '',
            nationality : '',
            birth_place: '',
            occupation: '',
      },
      submitted: false,
      showmodal: false,
      recipient_type: null,
      forms:[],
      bank_list:[],
    };
  },
  validations: {
    recipients: {
      bank_name : {
        required,
      },
      province_r : {
        required,
      }
    },
  },
  methods: {
    formSubmitted(){
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success ml-2',
          cancelButton: 'btn btn-secondary'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: 'Process Transaction ?',
        text: "Are you sure all the data is correct?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Process!',
        reverseButtons: true
      }).then((result) => {
        this.isDisable = true;
        this.isLoading = true;
        if (result.isConfirmed) {
         let admin = JSON.parse(localStorage.getItem("user")).user_id
          let admin_id = JSON.parse(localStorage.getItem("user")).id
          let send_Amount = this.remove_format(this.sendAmount)
          let rec_Amount = this.splitReceived(this.receivedAmount)

          let data = new FormData()
            data.append("des_country", this.desCon.country);
            data.append("bank_name", this.recID.bank_name);
            data.append("id_rec", this.recID.id_rec);
            data.append("customer_rate", this.desCon.rate);
            data.append("fee", this.desCon.fee);
            data.append("recipient_gets", rec_Amount);
            data.append("send", send_Amount);
            data.append("invoice_num", this.inv_num);
            data.append("source_of_fund", this.sourceID.id_fund);
            data.append("purpose_of_transfer", this.purposeID.id_purpose);
            data.append("create_by", this.userID.user_id);
            data.append("admin_name", admin);
            data.append("document", this.invoice);
            data.append("note", this.note);
            data.append("admin", admin_id);
          
          axios.post('/v1/add-trx-aff', data)
          .then((res) => {
            if(res.data.success){
              Swal.fire("Processing", "Your transaction was added", "success");
              this.$router.push({ name: "home" })
              this.isDisable = false;
              this.isLoading = true;
            } else {
              Swal.fire("Something Wrong", res.data.message , "error");
              this.isDisable = false;
              this.isLoading = false;
            }
          })
        }
      })
    },
    getCountry(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-rate/${id}`)
      .then((res) => {
        this.destination_country = res.data.data
        if(this.$route.params.country) {
          let Item = this.destination_country.find(
            (x) => x.country == this.$route.params.country
          );
          this.desCon = Object.assign({}, Item);
          this.valueDesCon = `${this.desCon.currency_code}-${this.desCon.rate}-${this.desCon.fee}-${this.desCon.country}`;
          if(this.desCon.type == 1) {
            this.recipient_type = 1
          } else {
            this.recipient_type = 2
          }
          axios.get(`/v1/get-field/${this.recipient_type}/${this.desCon.currency_code}`)
          .then((res) => {
            this.isLoaded2 = true;
            this.MinMax = res.data.currency
            this.forms = res.data.remitter_parameters.recipient_information;
            if(this.$route.params.send) {
              this.sendAmount = this.$route.params.send;
              setValue(this.$refs.sendAmount, this.remove_format(this.sendAmount));
            }
            if(this.$route.params.rec) {
              this.receivedAmount = this.$route.params.rec;
              setValue(this.$refs.receivedAmount, this.splitReceived(this.receivedAmount));
            }
          })
        }
      })
    },
    getBal(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-bal/${id}`)
      .then((res) => {
        this.balance = res.data.bal[0];
      })
    },
    submitDestinationCountry(option) {
      this.receivedAmount = "";
      this.sendAmount = "";
      this.userID = ''
      this.recID = ''
      this.sourceID = ''
      this.purposeID = ''
      document.getElementById('invoice').value = "";
      if(option.type == 1) {
        this.recipient_type = 1
      } else {
        this.recipient_type = 2
      }
      
      this.valueDesCon = `${option.currency_code}-${option.rate}-${option.fee}-${option.country}`;
      
      let id_curr = `${option.currency_code}`
      axios.get(`/v1/get-field/${this.recipient_type}/${id_curr}`)
      .then((res) => {
        this.MinMax = res.data.currency
      })
      this.$refs.wizard.reset()
    },
    currencyIndonesia() {
      if(this.desCon.country == null) {
        Swal.fire("Warning!!", "Select destination country first", "error");
        // this.receivedAmount = null;
        // this.sendAmount = null;
      } else {
        let value = this.valueDesCon.split("-")[1];
        let data = getValue(this.$refs.receivedAmount) * Number(value);
        setValue(this.$refs.sendAmount, data);
        this.send_validation = getValue(this.$refs.receivedAmount);
      }
    },

    currencyForeign() {
      if (this.desCon.country == null) {
        Swal.fire("Warning!!", "Select destination country first", "error");
        // this.receivedAmount = null;
        // this.sendAmount = null;
      } else {
        let value = this.valueDesCon.split("-")[1];
        let data;
        if (getValue(this.$refs.sendAmount) >= Number(value)) {
          let first = getValue(this.$refs.sendAmount) / Number(value);
          data = Math.round((first + Number.EPSILON) * 100) / 100;
        } else {
          data = 0;
        }

        setValue(this.$refs.receivedAmount, data);
          this.send_validation = getValue(this.$refs.receivedAmount);
      }
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceCr(value) {
      let val = (value / 1).toFixed(0).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceVND(value) {
      let val = (value / 1).toFixed(3).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    splitReceived(value) {
      let res = value.split(/[',', .]/);
      let data = [];
      for (let i = 0; i < res.length - 1; i++) {
        data.push(res[i]);
      }
      let join = data.join("");
      let result = join + "." + res[res.length - 1];
      return result;
    },
    remove_format(money) {
      return money.toString().replace(/[^0-9]/g, "");
    },
    getSenderbyCountry(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-sender/${id}`)
      .then((res) => {
        this.users = res.data.user
        this.users.forEach(function (element) {
          element.item = `${element.sender_name} - ${element.idcard_num}`;
        })
        if(this.$route.params.sender) {
           let Item = this.users.find(
            (x) => x.user_id == this.$route.params.sender
          );
          this.userID = Object.assign({}, Item);
          axios.get(`v1/get-recs/${this.$route.params.sender}`)
          .then((res) => {
            this.recs = res.data.success.filter((x) => {
              return x.des_country == this.$route.params.country
            })
            this.recs.forEach(function (element) {
              element.item = `${element.recipient_name} - ${element.acc_number ? element.acc_number : element.iban ? element.iban : element.phone_n}`;
              element.primary = `${element.acc_number ? element.acc_number : element.iban ? element.iban : element.phone_n}`;
            })
            this.recID = this.recs.find(
              (x) => x.id_rec == this.$route.params.ben
            );
             this.recs = res.data.success.filter((x) => {
              return x.type_rec == this.recID.type_rec
            })
            if(this.recID.type_rec == 1) {
              this.recipient_type = 1
            } else {
              this.recipient_type = 2
            }
            // this.recID = Object.assign({}, Items);
          })
        }
      })
    },
    getRecipient(){
      this.recID = '';
      let id  = this.userID.user_id
      axios.get(`v1/get-recs/${id}`)
        .then((res) => {
          this.recs = res.data.success.filter((x) => {
            return x.des_country == this.desCon.country && x.type_rec == this.recipient_type
          })
          this.recs.forEach(function (element) {
            element.item = `${element.recipient_name} - ${element.acc_number ? element.acc_number : element.iban ? element.iban : element.phone_n}`;
            element.primary = `${element.acc_number ? element.acc_number : element.iban ? element.iban : element.phone_n}`;
          })
        })
    },
    onChangeInvoice(e){
      this.invoice = e.target.files[0];
    },
    async next1(){
      await this.currencyIndonesia();
      // await this.currencyForeign();
      
      if(this.desCon == null || this.sendAmount == null || this.receivedAmount == null){
        Swal.fire("Warning!!", `Input Amount Send`, "error");
        this.sendAmount = ''
        this.receivedAmount = ''
        return false;
      }else {
        if(this.desCon == '' || this.sendAmount == '' || this.receivedAmount == ''){
        Swal.fire("Warning!!", `Input Amount Send`, "error");
        this.sendAmount = ''
        this.receivedAmount = ''
        return false;
        } else {
          let send_value = this.remove_format(this.sendAmount)
          if(Number(this.balance.balance) < (Number(this.desCon.fee) + Number(send_value))) {
            Swal.fire("Warning!!", `insufficient funds`, "error");
            return false;
          } else {
            let rec_value = this.splitReceived(this.receivedAmount)
            if(Number(rec_value) < Number(this.MinMax.min)){
              Swal.fire("Warning!!", `Minimum Amount send is ${this.formatPriceCr(this.MinMax.min)}`, "error");
              return false;
            } else {
              if (Number(rec_value) > Number(this.MinMax.max)){
                Swal.fire("Warning!!", `Maximal Amount send is ${this.MinMax.code.substring(0,3)} ${this.formatPriceCr(this.MinMax.max)}`, "error");
                return false;
              } else {  
                this.getSenderbyCountry();
                return true;
              }
            }
          }
        }
      }
      // return true;
    },
    next2(){
      if(this.userID && this.recID){
        return true;
      } else {
        Swal.fire("Warning!!", `Select Contact Info`, "error");
        return false;
      }
      // return true;
    },
    next3(){
      let send_value = this.remove_format(this.sendAmount)      
      if(this.purposeID && this.sourceID){
        if(Number(send_value) >= 100000000 && !this.invoice){
          Swal.fire("Warning!!", `Please Upload Invoice!!`, "error");
          return false;
        } else {
          if(this.desCon.country == 'USD Worldwide' && !this.invoice) {
            Swal.fire("Send to USD Worldwide!", `Please Upload Invoice!!`, "error");
            return false;
          } else {
            if(this.desCon.country == 'Euro Worldwide' && !this.invoice) {
              Swal.fire("Send to Euro Worldwide!", `Please Upload Invoice!!`, "error");
              return false;
            } else {
              if(this.desCon.country == 'Alibaba USD' && !this.invoice) {
                Swal.fire("Send to Alibaba USD!", `Please Upload Invoice!!`, "error");
                return false;
              } else {
                return true;
              }
            }
          }
        }
      } else {
        Swal.fire("Warning!!", `Select purpose and source of fund`, "error");
        return false;
      }
    },
    getPurpose(){
      axios.get(`v1/purpose`)
      .then((res) => {
        this.purpose = res.data.source
      })
    },
    getSource(){
      axios.get(`v1/source`)
      .then((res) => {
        this.source = res.data.source
      })
    },
    generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxx-xxx-yxxxx'.replace(/[xy]/g, function(c) {
          var r = (d + Math.random()*16)%16 | 0;
          d = Math.floor(d/16);
          return (c=='x' ? r : (r&0x7|0x8)).toString(16);
      });
      this.inv_num = uuid.toUpperCase();
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.recipients.bank_name.$touch();
      this.isDisable = true;

      if(!this.$v.recipients.bank_name.$invalid) {
          let data ={
            f_name: this.recipients.f_name,
            l_name: this.recipients.l_name,
            company_name: this.recipients.company_name,
            des_country: this.desCon.country,
            acc_number: this.recipients.acc_number,
            phone_n: this.recipients.phone_n,
            bank_name: this.recipients.bank_name.id_bank ? this.recipients.bank_name.id_bank : null,
            address: this.recipients.address,
            create_by: this.userID.user_id,
            bsb_num: this.recipients.bsb_code,
            bank_branch: this.recipients.branch_name,
            branch_code: this.recipients.branch_code,
            ifsc_code: this.recipients.ifsc,
            swift_code: this.recipients.swift,
            iban_code: this.recipients.iban,
            bank_address: this.recipients.bank_add,
            address_city: this.recipients.city,
            address_prov: this.recipients.province_r ? this.recipients.province_r : null,
            address_post: this.recipients.postalcode,
            acc_type_r: this.recipients.account_type,
            rec_type : this.recipient_type,
            transit_code: this.recipients.transit_code,   
            email: this.recipients.email,
            birth_date : this.recipients.birth_date,
            id_type : this.recipients.id_type,
            id_number : this.recipients.id_number,
            bik_code : this.recipients.bik_code,
            sort_code : this.recipients.sort_code,
            nationality : this.recipients.nationality,
            usd_bank_name: this.recipients.usd_bank_name,
            birth_place: this.recipients.birth_place,
            occupation: this.recipients.occupation,
        };
        axios.post(`v1/add-rec-aff`, data)
        .then((res) => {
            if(res.data.success){
                this.$bvToast.toast("Add Recipient success", {
                  title: `Success`,
                  solid: true,
                  variant: 'primary'
                });
                this.recID = '';
                let id  = this.userID.user_id
                axios.get(`v1/get-recs/${id}`)
                  .then((res) => {
                    this.recs = res.data.success.filter((x) => {
                      return x.des_country == this.desCon.country
                    })
                    this.recs.forEach(function (element) {
                      element.item = `${element.recipient_name} - ${element.acc_number ? element.acc_number : element.iban ? element.iban : element.phone_n}`;
                      element.primary = `${element.acc_number ? element.acc_number : element.iban ? element.iban : element.phone_n}`;
                    })
                    this.recID = Object.assign({}, this.recs[0]);
                  })
                this.showmodal = false;
                this.submitted = false;
                this.onChange3();
                this.isDisable = false;
            } else {
                this.$bvToast.toast("Add Recipient failed", {
                  title: `Error`,
                  solid: true,
                  variant: 'danger'
                });
                this.submitted = false;
                this.isDisable = false;
            }
        })
      } else {
        Swal.fire("Warning", "Select Required", "error");
      }
    },
    onChange(){
        this.userID = '',
        this.recID = '',
        this.recipients.user_id = '',
        this.recipients.f_name = null,
        this.recipients.l_name = null,
        this.recipients.company_name = null,
        this.recipients.des_country = '',
        this.recipients.bank_name = '',
        this.recipients.usd_bank_name = null,
        this.recipients.acc_number = null,
        this.recipients.phone_n = null,
        this.recipients.iban = null,
        this.recipients.swift = null,
        this.recipients.bsb_code = null,
        this.recipients.ifsc = null,
        this.recipients.bik_code = null,
        this.recipients.transit_code = null,
        this.recipients.sort_code = null,
        this.recipients.branch_name = null,
        this.recipients.branch_code = null,
        this.recipients.address = null,
        this.recipients.bank_add = null,
        this.recipients.city = null,
        this.recipients.province_r = '',
        this.recipients.postalcode = null,
        this.recipients.region = null,
        this.recipients.email = null,
        this.recipients.account_type = null,
        this.recipients.id_type = null,
        this.recipients.id_number = null,
        this.recipient_type = 1
        axios.get(`v1/get-field/1/${this.desCon.currency_code}`)
          .then((res) => {
            this.MinMax = res.data.currency
          })
        // axios.get(`v1/banklist-vendor/${this.desCon.currency_code}`)
        // .then((res) => {
        //     this.bank_list = res.data.data;
        // })
    },
    onChange2(){
        this.userID = '',
        this.recID = '',
        this.recipients.user_id = '',
        this.recipients.f_name = null,
        this.recipients.l_name = null,
        this.recipients.company_name = null,
        this.recipients.des_country = '',
        this.recipients.bank_name = '',
        this.recipients.usd_bank_name = null,
        this.recipients.acc_number = null,
        this.recipients.phone_n = null,
        this.recipients.iban = null,
        this.recipients.swift = null,
        this.recipients.bsb_code = null,
        this.recipients.ifsc = null,
        this.recipients.bik_code = null,
        this.recipients.transit_code = null,
        this.recipients.sort_code = null,
        this.recipients.branch_name = null,
        this.recipients.branch_code = null,
        this.recipients.address = null,
        this.recipients.bank_add = null,
        this.recipients.city = null,
        this.recipients.province_r = '',
        this.recipients.postalcode = null,
        this.recipients.region = null,
        this.recipients.email = null,
        this.recipients.account_type = null,
        this.recipients.id_type = null,
        this.recipients.id_number = null,
        this.recipient_type = 2
        axios.get(`v1/get-field/2/${this.desCon.currency_code}`)
        .then((res) => {
          this.MinMax = res.data.currency
        })
    },
    onChange3(){
        this.recipients.user_id = '',
        this.recipients.f_name = null,
        this.recipients.l_name = null,
        this.recipients.company_name = null,
        this.recipients.des_country = '',
        this.recipients.bank_name = '',
        this.recipients.usd_bank_name = null,
        this.recipients.acc_number = null,
        this.recipients.phone_n = null,
        this.recipients.iban = null,
        this.recipients.swift = null,
        this.recipients.bsb_code = null,
        this.recipients.ifsc = null,
        this.recipients.bik_code = null,
        this.recipients.transit_code = null,
        this.recipients.sort_code = null,
        this.recipients.branch_name = null,
        this.recipients.branch_code = null,
        this.recipients.address = null,
        this.recipients.bank_add = null,
        this.recipients.city = null,
        this.recipients.province_r = '',
        this.recipients.postalcode = null,
        this.recipients.region = null,
        this.recipients.email = null,
        this.recipients.account_type = null,
        this.recipients.id_type = null,
        this.recipients.id_number = null
    },
    hideModal() {
        this.submitted = false;
        this.showmodal = false;
    },
    addRecipient(){
      if(!this.userID){
        Swal.fire("Warning!!", `Select Sender first`, "error");
      } else {
        this.showmodal = true;
        this.$refs.multiselect.deactivate();
        axios.get(`v1/get-field/${this.recipient_type}/${this.desCon.currency_code}`)
        .then((res) => {
          this.isLoaded2 = true;
          this.forms = res.data.remitter_parameters.recipient_information;
        })
        axios.get(`v1/banklist-vendor/${this.desCon.currency_code}`)
        .then((res) => {
          this.bank_list = res.data.data;
        })
      }
    },
  },
  directives: {
    currency: CurrencyDirective,
  },
  created () {
    this.getCountry();
    // this.getSender();
    this.getPurpose();
    this.getSource();
    this.getBal();
    this.generateUUID();
    axios.get(`v1/get-field/1/MYR`)
    .then((res) => {
      this.forms = res.data.remitter_parameters.recipient_information;
    })
    if(this.$route.params.ben) {
      this.isdisabd = true
    }
  },
};
</script>

<template>
  <div>
    <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"/>
  </div>
  <Layout>
    <PageHeader :title="title" />
      <div class="row">
        <div class="col">
          <form-wizard
            color="#167df0"
            layout="vertical"
            finish-button-text="Submit"
            back-button-text="Previous"
            class="vertical-steps steps-transparent"
            @on-complete="formSubmitted"
            ref="wizard"
            error-color="#a94442"
          >
            <tab-content
              title="Send Amount"
              icon="ri-file-text-line"
              class="form-mid"
              :before-change="next1"
            >
                <b-col cols="12" class="mb-4">
                  <h5 class="mb-0">Send Amount</h5>
                  <small class="text-muted">How much do you sent</small>
                </b-col>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Destination Country"
                    label-for="des_country"
                  >
                    <multiselect
                      v-model="desCon" 
                      :options="destination_country" 
                      label="country"
                      placeholder="Select Country"
                      required
                      :disabled="isdisabd"
                      :selectLabel="null"
                      :deselectLabel="null"
                      :allow-empty="false"
                      @select="submitDestinationCountry"
                    >
                    <template slot="singleLabel" slot-scope="{ option }"><img :src="option.flag" width="25" height="25"><span> {{ option.country }}</span></template>
                    <template slot="option" slot-scope="{ option }"><img :src="option.flag" width="25" height="25"><span> {{ option.country }}</span></template>
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="this.desCon.type == 3 && !this.$route.params.ben || this.desCon.type == 2 && !this.$route.params.ben" cols="6" v-on:click="onChange2">
                  <b-form-radio :class="recipient_type == 2 ?'btn btn-info mb-4' : 'btn btn-info btn-light mb-4 text-muted'" v-model="recipient_type" value="2">Corporate</b-form-radio>
                </b-col>
                <b-col v-if="this.desCon.type == 3 && !this.$route.params.ben || this.desCon.type == 1 && !this.$route.params.ben" cols="6" v-on:click="onChange">
                  <b-form-radio :class="recipient_type == 1 ?'btn btn-info mb-4' : 'btn btn-info btn-light mb-4 text-muted'" v-model="recipient_type" value="1">Personal</b-form-radio>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label for="youSend" class="form-label">You Send</label>
                    <div class="input-group">
                      <input 
                        type="text" 
                        class="form-control" 
                        data-bv-field="youSend" 
                        id="youSend" 
                        placeholder="Enter Amount"
                        ref="sendAmount"
                        v-model="sendAmount"
                        autocomplete="off"
                        @keyup="currencyForeign"
                        required
                        v-currency="{
                          locale: 'default',
                          currency: null,
                          precision: 0,
                          distractionFree: false,
                          autoDecimalMode: false,
                          valueRange: { min: 0 },
                          allowNegative: false,
                        }"
                        >
                      <span class="input-group-text w-5"> IDR </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <h4 class="text-muted mb-1"><img src="@/assets/images/rates-icon.svg" alt="Rates Icon" /> Rate 
                      <span v-if="this.desCon" class="fw-500 right">{{this.desCon.country == 'Vietnam' ? formatPriceVND(this.desCon.rate) :  this.desCon.rate >= 1000 ? formatPriceCr(this.desCon.rate) : formatPrice(this.desCon.rate)}} IDR</span>
                      <span v-else class="fw-500 right">0 IDR</span>
                    </h4>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <h4 class="text-muted"> <img src="@/assets/images/fee-icon.svg" alt="Rates Icon" /> Fee 
                      <span v-if="this.desCon" class="fw-500" style="float:right">{{formatPriceCr(this.desCon.fee)}} IDR</span>
                      <span v-else class="fw-500" style="float:right">0 IDR</span>
                    </h4>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label for="received" class="form-label">Recipient Gets</label>
                    <div class="input-group">
                      <input 
                        type="text" 
                        class="form-control" 
                        data-bv-field="received" 
                        id="received" 
                        placeholder="Enter Amount"
                        ref="receivedAmount"
                        v-model="receivedAmount"
                        autocomplete="off"
                        @keyup="currencyIndonesia"
                        @keypress="isNumber"
                        required
                        v-currency="{
                          locale: 'default',
                          currency: null,
                          precision: 2,
                          distractionFree: false,
                          autoDecimalMode: false,
                          valueRange: { min: 0 },
                          allowNegative: false,
                        }"
                        >
                      <span v-if="this.desCon" class="input-group-text w-5"> {{this.desCon.currency_code.substring(0,3)}} </span>
                      <span v-else class="input-group-text w-5"> IDR </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-5 mb-0">
                <b-col>
                  <b-form-group>
                    <h5 class="success" v-if="Number(this.balance.balance) > Number(this.desCon.fee ? this.desCon.fee : 0) + Number(this.remove_format(this.sendAmount ? this.sendAmount : 0 ))">Available Balance : {{this.formatPriceCr(this.balance.balance)}} </h5>
                    <h5 class="error" v-else>Available Balance : {{this.formatPriceCr(this.balance.balance)}}</h5>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
            <tab-content
              class="form-mid"
              title="Contact Info"
              icon="ri-account-circle-line"
              :before-change="next2"
            >
              <b-col cols="12" class="mb-4">
                <h5 class="mb-0">Sender & Recipient Info</h5>
                <small class="text-muted">Select sender and recipient</small>
              </b-col>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Select Sender"
                  >
                    <div class="input-group">
                      <multiselect
                        v-model="userID" 
                        :options="users" 
                        label="sender_name"
                        placeholder="Select Sender"
                        required
                        :selectLabel="null"
                        :deselectLabel="null"
                        @input="getRecipient"
                        :allow-empty="false"
                      >
                      </multiselect>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Select Recipient"
                  >
                    <multiselect
                      v-model="recID" 
                      :options="recs" 
                      :selectLabel="null"
                      :deselectLabel="null"
                      label="item"
                      placeholder="Select Recipient"
                      :showNoResults="false"
                      :allow-empty="false"
                      ref="multiselect"
                      required
                    >
                    <span slot="afterList" style="margin-left:1rem">No Recipient in list...<a style="margin-left:1rem" href="javascript:void(0);" class="btn btn-primary mb-2" @click="addRecipient"><i class="mdi mdi-plus mr-2"></i> Add Recipient</a></span>
                  </multiselect>
                  <div class="card mt-2 mb-0" v-if="recID">
                    <div class="card-body"  style="margin-bottom:-1rem">
                      <div class="row">
                        <div class="col-4">
                          <p>Account Holder</p>
                          <p>{{recID.acc_number ? 'Account Number' : recID.iban ? 'Iban' :'Alipay Number'}}</p>
                          <p>Account Bank</p>
                        </div>
                        <div class="col-4">
                          <p>{{recID.recipient_name}}</p>
                          <p>{{recID.primary}}</p>
                          <p>{{recID.usd_bank_name ? recID.usd_bank_name + ' (' + recID.nama_bank + ')' : recID.nama_bank}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Note (Optionals)"
                  >
                    <input 
                      type="text" 
                      class="form-control"
                      placeholder="Enter Note"
                      v-model="note"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
            <tab-content
              class="form-mid"
              title="Purpose Info"
              icon="ri-file-chart-line"
              :before-change="next3"
            >
              <b-col cols="12" class="mb-4">
                <h5 class="mb-0">Purpose & Source of Funds</h5>
                <small class="text-muted">Select Purpose & Source of Fund</small>
              </b-col>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Select Purpose"
                  >
                    <div class="input-group">
                      <multiselect
                        v-model="purposeID" 
                        :options="purpose" 
                        :selectLabel="null"
                        :deselectLabel="null"
                        label="name_en"
                        placeholder="Select Purpose"
                        :allow-empty="false"
                        required
                      >
                      </multiselect>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Select Source of Fund"
                  >
                    <multiselect
                      v-model="sourceID" 
                      :options="source" 
                      label="name_en"
                      placeholder="Select Source of Fund"
                      required
                      :selectLabel="null"
                      :deselectLabel="null"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Upload Invoice"
                  >
                    <input
                      id="invoice"
                      type="file"
                      class="form-control"
                      @input="onChangeInvoice"
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
            <tab-content
              class="form-mid"
              title="Confirmation"
              icon="ri-information-line"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-4"
                >
                  <h5 class="mb-0">
                    Confirmation
                  </h5>
                  <small class="text-muted">Confirm your transfer</small>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <div class="card">
                      <div class="card-body">
                        <div class="table-responsive" style="margin-bottom:-1rem">
                          <table class="table table-centered mb-0 table-nowrap">
                            <thead class="bg-light">
                              <tr>
                                <th>Sender Details</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody class="lister">
                              <tr>
                                <td><p>Name</p></td>
                                <td><p>{{this.userID.sender_name}}</p></td>
                              </tr>
                              <tr>
                                <td><p>Address</p></td>
                                <td><p>{{this.userID.user_address}}</p></td>
                              </tr>
                              <tr>
                                <td><p>Country</p></td>
                                <td><p>{{this.userID.user_country}}</p></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="card">
                      <div class="card-body">
                        <div class="table-responsive" style="margin-bottom:-1rem;max-height: 14.7rem" data-simplebar>
                          <table class="table table-centered mb-0 table-nowrap">
                            <thead class="bg-light">
                              <tr>
                                <th>Recipient Details</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody class="lister">
                              <tr>
                                <td><p>Name</p></td>
                                <td><p>{{this.recID.recipient_name}}</p></td>
                              </tr>
                              <tr>
                                <td><p>Destination Country</p></td>
                                <td><p>{{this.recID.des_country}}</p></td>
                              </tr>
                              <tr v-if="this.recID.acc_number">
                                <td><p>Account Number</p></td>
                                <td><p>{{this.recID.acc_number}}</p></td>
                              </tr>
                              <tr v-if="this.recID.phone_n">
                                <td><p>Phone</p></td>
                                <td><p>{{this.recID.phone_n}}</p></td>
                              </tr>
                              <tr v-if="this.recID.iban">
                                <td><p>Iban</p></td>
                                <td><p>{{this.recID.iban}}</p></td>
                              </tr>
                              <tr v-if="this.recID.swift">
                                <td><p>Swift</p></td>
                                <td><p>{{this.recID.swift}}</p></td>
                              </tr>
                              <tr>
                                <td><p>Bank Name</p></td>
                                <td><p>{{this.recID.usd_bank_name ? this.recID.usd_bank_name + ' (' + this.recID.nama_bank + ')' : this.recID.nama_bank}}</p></td>
                              </tr>
                              <tr v-if="this.recID.bsb_code">
                                <td><p>BSB Number</p></td>
                                <td><p>{{this.recID.bsb_code}}</p></td>
                              </tr>
                              <tr v-if="this.recID.ifsc">
                                <td><p>IFSC</p></td>
                                <td><p>{{this.recID.ifsc}}</p></td>
                              </tr>
                              <tr v-if="this.recID.transit_code">
                                <td><p>Transit Code</p></td>
                                <td><p>{{this.recID.transit_code}}</p></td>
                              </tr>
                              <tr v-if="this.recID.bik_code">
                                <td><p>BIK Code</p></td>
                                <td><p>{{this.recID.bik_code}}</p></td>
                              </tr>
                              <tr v-if="this.recID.sort_code">
                                <td><p>Sort Code</p></td>
                                <td><p>{{this.recID.sort_code}}</p></td>
                              </tr>
                              <tr v-if="this.recID.branch_name">
                                <td><p>Branch Name</p></td>
                                <td><p>{{this.recID.branch_name}}</p></td>
                              </tr>
                              <tr v-if="this.recID.branch_code">
                                <td><p>Branch Code</p></td>
                                <td><p>{{this.recID.branch_code}}</p></td>
                              </tr>
                               <tr v-if="this.recID.bank_add">
                                <td><p>Bank Address</p></td>
                                <td><p>{{this.recID.bank_add}}</p></td>
                              </tr>
                              <tr v-if="this.recID.address">
                                <td><p>Address</p></td>
                                <td><p>{{this.recID.address}}</p></td>
                              </tr>
                             
                              <tr v-if="this.recID.city">
                                <td><p>City</p></td>
                                <td><p>{{this.recID.city}}</p></td>
                              </tr>
                              <tr v-if="this.recID.province_name">
                                <td><p>Province</p></td>
                                <td><p>{{this.recID.province_name}}</p></td>
                              </tr>
                              <tr v-if="this.recID.postalcode">
                                <td><p>Postal Code</p></td>
                                <td><p>{{this.recID.postalcode}}</p></td>
                              </tr>
                              <tr v-if="this.recID.region">
                                <td><p>Region</p></td>
                                <td><p>{{this.recID.region}}</p></td>
                              </tr>
                              <tr v-if="this.recID.email">
                                <td><p>Email</p></td>
                                <td><p>{{this.recID.email}}</p></td>
                              </tr>
                              <tr v-if="this.recID.account_type">
                                <td><p>Account Type</p></td>
                                <td><p>{{this.recID.account_type}}</p></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <div class="card">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="card-body">
                              <div class="table-responsive" style="font-size:1.2rem;line-height:.8rem">
                                <table class="table table-centered mb-0 table-nowrap">
                                  <tbody>
                                  <tr class="bg-light">
                                    <th scope="row">Source of Fund </th>
                                    <td style="font-weight:900">{{this.sourceID.name_en}}</td>
                                    <th scope="row" class="text-right" colspan="2" style="border-bottom: 1px solid lightgrey">Balance</th>
                                    <td style="font-weight:900;border-bottom: 1px solid lightgrey" class="text-right">{{this.formatPriceCr(this.balance.balance)}} IDR</td>
                                  </tr>
                                  <tr class="bg-light">
                                    <th scope="row">Purpose </th>
                                    <td style="font-weight:900">{{this.purposeID.name_en}}</td>
                                    <th scope="row" class="text-right" colspan="2">Send Amount</th>
                                    <td style="font-weight:900" class="text-right">{{Number(this.splitReceived(this.receivedAmount)) %1 != 0 ? this.receivedAmount : this.formatPriceCr(this.splitReceived(this.receivedAmount))}} {{this.desCon.currency_code ? this.desCon.currency_code.substring(0,3) : 'IDR'}}</td>
                                  </tr>
                                  <tr class="bg-light">
                                    <th scope="row">{{this.note ? 'Note ' : ''}} </th>
                                    <td style="font-weight:900;display:flex;width: 25%" rowspan="3">{{this.note}}</td>
                                    <th scope="row" class="text-right" colspan="2">Rate</th>
                                    <td style="font-weight:900" class="text-right">{{this.desCon.country == 'Vietnam' ? formatPriceVND(this.desCon.rate) : this.desCon.rate >= 1000 ? formatPriceCr(this.desCon.rate) : formatPrice(this.desCon.rate)}} IDR</td>
                                  </tr>
                                  <tr class="bg-light">
                                    <th scope="row" class="text-right" colspan="4">Fee</th>
                                    <td style="font-weight:900" class="text-right">{{formatPriceCr(this.desCon.fee)}} IDR</td>
                                  </tr>
                                  <tr class="bg-light">
                                    <th scope="row" class="text-right" colspan="4" style="color:black">Total</th>
                                    <td style="font-weight:900;color:black;border-bottom: 1px solid lightgrey" class="text-right">{{formatPriceCr(Number(this.remove_format(this.sendAmount ? this.sendAmount : 0)) + Number(this.desCon.fee))}} IDR</td>
                                  </tr>
                                  <tr class="bg-light">
                                    <th scope="row" class="text-right" colspan="4" style="color:#167df0">Remaining Balance</th>
                                    <td style="font-weight:900;color:#167df0" class="text-right">{{formatPriceCr(Number(this.balance.balance) - Number(this.remove_format(this.sendAmount ? this.sendAmount : 0)) - Number(this.desCon.fee))}} IDR</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>
        </div>
      </div>
      <b-modal
        id="modal-1"
        v-model="showmodal"
        title="Add Recipient"
        title-class="text-dark font-18"
        hide-footer
        scrollable
      >
        <form @submit.prevent="handleSubmit">
          <div content-class="p-3 text-muted">
            <div v-if="recipient_type == 2" class="border-0 px-3">
              <div v-if="forms.company_name">
              <div class="form-group row" v-if="forms.company_name.mandatory">
                  <label for="com_name">Company Name</label>
                  <input
                      id="com_name"
                      v-model="recipients.company_name"
                      type="text"
                      class="form-control"
                      placeholder="Enter Company Name"
                      required
                  />
              </div>
              <div class="form-group row">
                  <template v-if="this.desCon.country == 'Alibaba USD' || 
                  this.desCon.country == 'USD Worldwide'">
                    <label for="bank_name">Country</label>
                    <multiselect 
                        v-model="recipients.bank_name" 
                        :options="bank_list"
                        label="nama_bank"
                        :selectLabel="null"
                        :deselectLabel="null"
                        placeholder="Select Country"
                        :allow-empty="false"
                        required
                    ></multiselect>
                  </template>
                  <template v-else>
                    <label for="bank_name">Bank Name</label>
                    <multiselect 
                        v-model="recipients.bank_name" 
                        :options="bank_list"
                        label="nama_bank"
                        :selectLabel="null"
                        :deselectLabel="null"
                        placeholder="Select Bank Name"
                        :allow-empty="false"
                      required
                    ></multiselect>
                  </template>
                  <small
                    class="mb-0 text-danger"
                    v-if="
                      !$v.recipients.bank_name.required &&
                      $v.recipients.bank_name.$error
                    "
                  >
                    Select is required
                  </small>
              </div>
              <div class="form-group row" v-if="forms.usd_bank_name.mandatory">
                  <label for="usd_bank_name">USD Bank Name</label>
                  <input
                      id="usd_bank_name"
                      v-model="recipients.usd_bank_name"
                      type="text"
                      class="form-control"
                      placeholder="Enter USD Bank Name"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.acc_number.mandatory">
                  <label for="acc_number" v-if="this.desCon.country ==  'Argentina'">CBU</label>
                  <label for="acc_number" v-else>Account Number</label>
                  <input
                      id="acc_number"
                      v-model="recipients.acc_number"
                      type="text"
                      class="form-control"
                      :placeholder="this.desCon.country ==  'Argentina' ? 'Enter CBU' :'Enter Account Number'"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.phone_n.mandatory">
                  <label for="phone_n">Phone Number</label>
                  <input
                      id="phone_n"
                      v-model="recipients.phone_n"
                      type="number"
                      class="form-control"
                      placeholder="Enter Phone Number"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.iban.mandatory">
                  <label for="iban">IBAN</label>
                  <input
                      id="iban"
                      v-model="recipients.iban"
                      type="text"
                      class="form-control"
                      placeholder="Enter IBAN"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.swift.mandatory">
                  <label for="swift">Swift Code</label>
                  <input
                      id="swift"
                      v-model="recipients.swift"
                      type="text"
                      class="form-control"
                      placeholder="Enter Swift Code"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.bsb_code.mandatory">
                  <label for="bsb_code">BSB Number</label>
                  <input
                      id="bsb_code"
                      v-model="recipients.bsb_code"
                      type="text"
                      class="form-control"
                      placeholder="Enter BSB Number"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.ifsc.mandatory">
                  <label for="ifsc">IFSC</label>
                  <input
                      id="ifsc"
                      v-model="recipients.ifsc"
                      type="text"
                      class="form-control"
                      placeholder="Enter IFSC"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.bik_code.mandatory">
                  <label for="bik_code">BIK Code</label>
                  <input
                      id="bik_code"
                      v-model="recipients.bik_code"
                      type="text"
                      class="form-control"
                      placeholder="Enter BIK Code"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.transit_code.mandatory">
                  <label for="transit_code">Transit Code</label>
                  <input
                      id="transit_code"
                      v-model="recipients.transit_code"
                      type="text"
                      class="form-control"
                      placeholder="Enter Transit Code"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.sort_code.mandatory">
                  <label for="sort_code">Sort Code</label>
                  <input
                      id="sort_code"
                      v-model="recipients.sort_code"
                      type="text"
                      class="form-control"
                      placeholder="Enter Sort Code"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.branch_name.mandatory">
                  <label for="branch_name">Branch Name</label>
                  <input
                      id="branch_name"
                      v-model="recipients.branch_name"
                      type="text"
                      class="form-control"
                      placeholder="Enter Branch Name"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.branch_code.mandatory">
                  <label for="branch_code">Branch Code</label>
                  <input
                      id="branch_code"
                      v-model="recipients.branch_code"
                      type="text"
                      class="form-control"
                      placeholder="Enter Branch Code"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.bank_add.mandatory">
                  <label for="bank_add">Bank Address</label>
                  <input
                      id="bank_add"
                      v-model="recipients.bank_add"
                      type="text"
                      class="form-control"
                      placeholder="Enter Bank Address"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.address.mandatory">
                  <label for="address">Address</label>
                  <input
                      id="address"
                      v-model="recipients.address"
                      type="text"
                      class="form-control"
                      placeholder="Enter Address"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.city.mandatory">
                  <label for="city">City</label>
                  <input
                      id="city"
                      v-model="recipients.city"
                      type="text"
                      class="form-control"
                      placeholder="Enter City"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.province_r.mandatory">
                  <label for="province_r">Province</label>
                  <select class="form-control" v-model="recipients.province_r" required>
                    <option value='' hidden>Select Province</option>
                      <option value="7">Australia Capital</option>
                      <option value="1">New South Wales</option>
                      <option value="8">Northern Territory</option>
                      <option value="2">Queensland</option>
                      <option value="3">South Australia</option>
                      <option value="4">Tasmania</option>
                      <option value="5">Victoria</option>
                      <option value="6">Western Australia</option>
                  </select>
              </div>
              <div class="form-group row" v-if="forms.postalcode.mandatory">
                  <label for="postalcode">Postal Code</label>
                  <input
                      id="postalcode"
                      v-model="recipients.postalcode"
                      type="text"
                      class="form-control"
                      placeholder="Enter Postal Code"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.region.mandatory">
                  <label for="region">Region</label>
                  <input
                      id="region"
                      v-model="recipients.region"
                      type="text"
                      class="form-control"
                      placeholder="Enter Region"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.email.mandatory">
                  <label for="email">Email</label>
                  <input
                      id="email"
                      v-model="recipients.email"
                      type="text"
                      class="form-control"
                      placeholder="Enter Email"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.account_type.mandatory">
                  <label for="account_type">Account Type</label>
                  <input
                      id="account_type"
                      v-model="recipients.account_type"
                      type="text"
                      class="form-control"
                      placeholder="Enter Account Type"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.id_type.mandatory">
                  <label for="id_type">ID Type</label>
                  <input
                      id="id_type"
                      v-model="recipients.id_type"
                      type="text"
                      class="form-control"
                      placeholder="Enter ID Type"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.id_number.mandatory">
                  <label for="id_number">ID Number</label>
                  <input
                      id="id_number"
                      v-model="recipients.id_number"
                      type="text"
                      class="form-control"
                      placeholder="Enter ID Number"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.birth_date.mandatory">
                  <label for="birth_date">Birth Date</label>
                  <input
                      id="birth_date"
                      v-model="recipients.birth_date"
                      type="date"
                      class="form-control"
                      placeholder="Enter Birth Date"
                      required
                  />
              </div>
              <div class="form-group row" v-if="forms.nationality.mandatory">
                  <label for="nationality">Nationality</label>
                  <input
                      id="nationality"
                      v-model="recipients.nationality"
                      type="text"
                      class="form-control"
                      placeholder="Enter Nationality"
                      required
                  />
              </div>
              <div class="text-right">
                  <b-button variant="danger" @click="hideModal">Cancel</b-button>
                  <button type="submit" class="btn btn-success ml-1" :disabled="isDisable">Submit</button>
              </div>
              </div>
            </div>
            <div v-if="recipient_type == 1" class="border-0 px-3">
              <div v-if="forms.f_name">
                <div class="form-group row" v-if="forms.f_name.mandatory">
                    <label for="fname">First Name</label>
                    <input
                        id="fname"
                        v-model="recipients.f_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter First Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.l_name.mandatory">
                    <label for="lname">Last Name</label>
                    <input
                        id="lname"
                        v-model="recipients.l_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Last Name"
                        required
                    />
                </div>
                <div class="form-group row">
                    <template v-if="this.desCon.country == 'Alibaba USD' || 
                    this.desCon.country == 'USD Worldwide'">
                      <label for="bank_name">Country</label>
                      <multiselect 
                          v-model="recipients.bank_name" 
                          :options="bank_list"
                          label="nama_bank"
                          :selectLabel="null"
                          :deselectLabel="null"
                          placeholder="Select Country"
                          :allow-empty="false"
                        required
                      ></multiselect>
                    </template>
                    <template v-else>
                      <label for="bank_name">Bank Name</label>
                      <multiselect 
                          v-model="recipients.bank_name" 
                          :options="bank_list"
                          label="nama_bank"
                          :selectLabel="null"
                          :deselectLabel="null"
                          placeholder="Select Bank Name"
                          :allow-empty="false"
                        required
                      ></multiselect>
                    </template>
                      <small
                      class="mb-0 text-danger"
                      v-if="
                        !$v.recipients.bank_name.required &&
                        $v.recipients.bank_name.$error
                      "
                    >
                      Select required is required
                    </small>
                </div>
                <div class="form-group row" v-if="forms.usd_bank_name.mandatory">
                    <label for="usd_bank_name">USD Bank Name</label>
                    <input
                        id="usd_bank_name"
                        v-model="recipients.usd_bank_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter USD Bank Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.acc_number.mandatory">
                    <label for="acc_number" v-if="this.desCon.country ==  'Argentina'">CBU</label>
                    <label for="acc_number" v-else>Account Number</label>
                    <input
                        id="acc_number"
                        v-model="recipients.acc_number"
                        type="text"
                        class="form-control"
                        :placeholder="this.desCon.country ==  'Argentina' ? 'Enter CBU' :'Enter Account Number'"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.phone_n.mandatory">
                    <label for="phone_n">Phone Number</label>
                    <input
                        id="phone_n"
                        v-model="recipients.phone_n"
                        type="number"
                        class="form-control"
                        placeholder="Enter Phone Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.iban.mandatory">
                    <label for="iban">IBAN</label>
                    <input
                        id="iban"
                        v-model="recipients.iban"
                        type="text"
                        class="form-control"
                        placeholder="Enter IBAN"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.swift.mandatory">
                    <label for="swift">Swift Code</label>
                    <input
                        id="swift"
                        v-model="recipients.swift"
                        type="text"
                        class="form-control"
                        placeholder="Enter Swift Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bsb_code.mandatory">
                    <label for="bsb_code">BSB Number</label>
                    <input
                        id="bsb_code"
                        v-model="recipients.bsb_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter BSB Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.ifsc.mandatory">
                    <label for="ifsc">IFSC</label>
                    <input
                        id="ifsc"
                        v-model="recipients.ifsc"
                        type="text"
                        class="form-control"
                        placeholder="Enter IFSC"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bik_code.mandatory">
                    <label for="bik_code">BIK Code</label>
                    <input
                        id="bik_code"
                        v-model="recipients.bik_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter BIK Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.transit_code.mandatory">
                    <label for="transit_code">Transit Code</label>
                    <input
                        id="transit_code"
                        v-model="recipients.transit_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Transit Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.sort_code.mandatory">
                    <label for="sort_code">Sort Code</label>
                    <input
                        id="sort_code"
                        v-model="recipients.sort_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Sort Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.branch_name.mandatory">
                    <label for="branch_name">Branch Name</label>
                    <input
                        id="branch_name"
                        v-model="recipients.branch_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Branch Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.branch_code.mandatory">
                    <label for="branch_code">Branch Code</label>
                    <input
                        id="branch_code"
                        v-model="recipients.branch_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Branch Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bank_add.mandatory">
                    <label for="bank_add">Bank Address</label>
                    <input
                        id="bank_add"
                        v-model="recipients.bank_add"
                        type="text"
                        class="form-control"
                        placeholder="Enter Bank Address"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.address.mandatory">
                    <label for="address">Address</label>
                    <input
                        id="address"
                        v-model="recipients.address"
                        type="text"
                        class="form-control"
                        placeholder="Enter Address"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.city.mandatory">
                    <label for="city">City</label>
                    <input
                        id="city"
                        v-model="recipients.city"
                        type="text"
                        class="form-control"
                        placeholder="Enter City"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.province_r.mandatory">
                    <label for="province_r">Province</label>
                    <select class="form-control" v-model="recipients.province_r" required>
                      <option value='' hidden>Select Province</option>
                        <option value="7">Australia Capital</option>
                        <option value="1">New South Wales</option>
                        <option value="8">Northern Territory</option>
                        <option value="2">Queensland</option>
                        <option value="3">South Australia</option>
                        <option value="4">Tasmania</option>
                        <option value="5">Victoria</option>
                        <option value="6">Western Australia</option>
                    </select>
                </div>
                <div class="form-group row" v-if="forms.postalcode.mandatory">
                    <label for="postalcode">Postal Code</label>
                    <input
                        id="postalcode"
                        v-model="recipients.postalcode"
                        type="text"
                        class="form-control"
                        placeholder="Enter Postal Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.region.mandatory">
                    <label for="region">Region</label>
                    <input
                        id="region"
                        v-model="recipients.region"
                        type="text"
                        class="form-control"
                        placeholder="Enter Region"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.email.mandatory">
                    <label for="email">Email</label>
                    <input
                        id="email"
                        v-model="recipients.email"
                        type="text"
                        class="form-control"
                        placeholder="Enter Email"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.account_type.mandatory">
                    <label for="account_type">Account Type</label>
                    <input
                        id="account_type"
                        v-model="recipients.account_type"
                        type="text"
                        class="form-control"
                        placeholder="Enter Account Type"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.id_type.mandatory">
                    <label for="id_type">ID Type</label>
                    <input
                        id="id_type"
                        v-model="recipients.id_type"
                        type="text"
                        class="form-control"
                        placeholder="Enter ID Type"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.id_number.mandatory">
                    <label for="id_number">ID Number</label>
                    <input
                        id="id_number"
                        v-model="recipients.id_number"
                        type="text"
                        class="form-control"
                        placeholder="Enter ID Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.birth_place.mandatory">
                    <label for="birth_place">Birth Place</label>
                    <input
                        id="birth_place"
                        v-model="recipients.birth_place"
                        type="text"
                        class="form-control"
                        placeholder="Enter Birth Place"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.birth_date.mandatory">
                    <label for="birth_date">Birth Date</label>
                    <input
                        id="birth_date"
                        v-model="recipients.birth_date"
                        type="date"
                        class="form-control"
                        placeholder="Enter Birth Date"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.nationality.mandatory">
                    <label for="nationality">Nationality</label>
                    <input
                        id="nationality"
                        v-model="recipients.nationality"
                        type="text"
                        class="form-control"
                        placeholder="Enter Nationality"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.occupation.mandatory">
                    <label for="occupation">Occupation</label>
                    <input
                        id="occupation"
                        v-model="recipients.occupation"
                        type="text"
                        class="form-control"
                        placeholder="Enter Occupation"
                        required
                    />
                </div>
                <div class="text-right">
                    <b-button variant="danger" @click="hideModal">Cancel</b-button>
                    <button type="submit" class="btn btn-success ml-1" :disabled="isDisable">Submit</button>
                </div>
                </div>
            </div>
          </div>
        </form>
      </b-modal>
    </Layout>
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>